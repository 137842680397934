import React from "react";
import ComingSoon from "../components/comingsoon";

const VehiclesOwner = () => {
    

    return (
      <ComingSoon />
    );
};

export default VehiclesOwner
