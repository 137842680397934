import React, { useState } from 'react';
import logo from '../assets/CatoDrive.png'; 
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  

  const menuItems = [
    { text: 'Home', link: '#' },
    { text: 'Become a renter', link: '#' },
    { text: 'Vehicles', link: '#' },
    { text: 'Why choose us', link: '#' },
    { text: 'Contact Us', link: '#' },
  ];

  return (
    <div>
      
      <div className="app-bar">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="img-fluid" />
        </div>
        
        <div className="menu-desktop">
          <Navbar  className="navbar-custom">
            
              <Nav className="navbar-center"> 
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/renter">Become a renter</Nav.Link>
                <Nav.Link href="/vehicles">Vehicles</Nav.Link>
                <Nav.Link href="/whyus">Why choose us</Nav.Link>
                <Nav.Link href="/contact">Contact Us</Nav.Link>
              </Nav>
            
          </Navbar>
          
          <div className="login-signup">
            <button onClick={() => navigate('/login')} className="login-btn">Log in</button>
            <button onClick={() => navigate('/signup')} className="signup-btn">Sign up</button>
          </div>
        </div>

        <div className="menu-icon" onClick={toggleDrawer(true)}>
          <img src="/path-to-menu-icon.png" alt="Menu" />
        </div>
      </div>

      {/* Mobile Drawer */}
      {drawerOpen && (
        <div className="mobile-drawer">
          <div className="drawer-header">
            <span onClick={toggleDrawer(false)} className="close-btn">X</span>
          </div>
          <ul className="drawer-menu">
            {menuItems.map((item, index) => (
              <li key={index}>
                <a href={item.link} className="drawer-menu-item">{item.text}</a>
              </li>
            ))}
            <li>
              <button className="login-btn">Log in</button>
            </li>
            <li>
              <button className="signup-btn">Sign up</button>
            </li>
          </ul>
        </div>
      )}


      <style jsx>{`
        
.app-bar {
  background-color: #2C3E50;
  padding: 24px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-desktop {
  display: flex;
  align-items: center;
  width: 72%;
  justify-content: space-between;
}

.navbar-center .nav-link {
  font-size: 18px;
  font-weight: normal;
  margin-right: 40px;
  color: white;
}

.navbar-center .nav-link:hover {
  color: white;
  text-decoration: underline #D35400 2px solid ; 
}
.login-btn,
.signup-btn {
  font-size: 18px;
  font-weight: normal;
  margin-left: 10px;
}

.login-btn {
  color: white;
  background-color: transparent;
  border: none;
  padding: 8px 18px;
  cursor: pointer;
}

.signup-btn {
  background-color: #D35400;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 18px;
  cursor: pointer;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .menu-desktop {
    width: 78%;
  }

  .navbar-center .nav-link {
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .menu-desktop {
    display: none;
  }

  .menu-icon {
    display: block;
  }
  .app-bar {
    padding: 20px;
  }
}

.mobile-drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #2C3E50;
  color: white;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-btn {
  font-size: 25px;
  color: white;
  cursor: pointer;
}

.drawer-menu {
  list-style: none;
  padding: 0;
}

.drawer-menu-item {
  padding: 15px;
  text-decoration: none;
  color: white;
  display: block;
}

.drawer-menu-item:hover {
  background-color: #1a2d44;
}

      `}</style>
    </div>
  );
}

export default Header;
