import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  
import img1 from '../assets/a1.png';
import img2 from '../assets/s1.png';
import img3 from '../assets/s2.png';

function Select() {
  const [selectedUserType, setSelectedUserType] = useState(null);
  const navigate = useNavigate(); 

  const handleSelect = (userType) => {
    setSelectedUserType(userType);
  };

  const handleNext = () => {
    if (selectedUserType === 'vehicleOwner') {
      navigate('/vehicle-owner'); 
    } else if (selectedUserType === 'customer') {
      navigate('/customer'); 
    }
  };

  return (
    <div className="select-container">
      <div className="select-content">
        <img src={img1} alt="Car" className="select-image" />
        
        <div className="select-card">
          <h2>Select User Type</h2>
          
          <div className="select-grid">
            <div 
              className={`select-item ${selectedUserType === 'vehicleOwner' ? 'selected' : ''}`} 
              onClick={() => handleSelect('vehicleOwner')}
            >
              <img src={img2} alt="Vehicle Owners" className="select-item-image" />
              <p>Vehicle Owners</p>
            </div>
            <div 
              className={`select-item ${selectedUserType === 'customer' ? 'selected' : ''}`} 
              onClick={() => handleSelect('customer')}
            >
              <img src={img3} alt="Customers" className="select-item-image" />
              <p>Customers</p>
            </div>
          </div>
          
          <div className="select-footer">
            <button className="next-btn" onClick={handleNext} disabled={!selectedUserType}>
              Next
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        .select-container {
    width: 100%;
    padding: 50px 100px;
}
.select-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}  
  .select-card {
    background-color: white;
    box-shadow: 36px 36px 54px 0 #98A2B3;
    border-radius: 20px;
    text-align: center;
    height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 44%;
  }

  .select-image {
    width: 560px;
  }
  
  h2 {
    font-size: 26px;
    font-weight: semibold;
    color: #101828;
  }
  
  .select-grid {
    display: flex;
    gap: 60px;
  }
  
  .select-item {
    text-align: center;
  }
  
  
  .select-item p {
    font-size: 1rem;
    color: #333;
  }
  
  .next-btn {
    background-color: #FF6A00;
    color: white;
    padding: 10px 30px;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s ease;
    width: 350px;
  }
  
  .next-btn:hover {
    background-color: #2C3E50;
  }

  .select-item {
    padding: 28px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .select-item:hover {
    border-color: #4A90E2; 
    box-shadow: 0 0 10px #4A90E2;
  }
  
  .select-item.selected {
    border-color: #4A90E2; 
    box-shadow: 0 0 10px #4A90E2;
  }
  
  @media (max-width: 768px) {
    .select-image {
      max-width: 90%;
    }
  
    .select-grid {
      flex-direction: column;
    }
  
    .select-item {
      margin-bottom: 20px;
    }
  
    .next-btn {
      width: 100%;
      padding: 12px 0;
    }
  }
  
      `}</style>
    </div>
  );
}

export default Select;
