import React from 'react';
import Driver from "../assets/driver-1 1.png";

const Joinus = () => {
  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      backgroundColor: 'white',
    }}>
      <img 
        src={Driver} 
        alt="Driver" 
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '1300px',
          maxHeight: '500px',
          objectFit: 'cover',
        }} 
      />

      <div style={{
        position: 'absolute',
        left: '15%',
        color: 'white',
      }}>
        <h1 style={{
          fontFamily: "'DM Serif Display', serif",
          fontSize: '45px',
          fontWeight: 'bold',
          marginBottom: '20px',
          lineHeight: '1.4',
        }}>
          Become A Driver <br />
          Your Time. Your Goals. You're The <br/>
          Boss.
        </h1>

        <div style={{
          alignItems: 'center',
        }}>
          <p style={{
            fontFamily: "'DM Serif Display', serif",
            fontSize: '25px',
            marginTop: '90px',
          }}>
            Subscribe And Join Us
          </p>

          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
          }}>
            <input 
              type="email" 
              placeholder="EMAIL" 
              style={{
                padding: '15px 15px',
                fontSize: '16px',
                borderRadius: '4px 4px 4px 4px', 
                border: '1px solid #ccc',
                width: '650px',
                marginRight: '0',
              }} 
            />
            <button style={{
              padding: '9px 20px',
              backgroundColor: '#f15a24',
              color: 'white',
              fontSize: '16px',
              border: '1px ',
              borderRadius: '4px 4px 4px 4px', 
              cursor: 'pointer',
              marginLeft:'-90px'
            }}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joinus;
