import React from "react";
import { BadgeCheck, Car, Clock, Headphones } from "lucide-react";
import Car1 from "../assets/Group 1000004264.png";

export default function CarRentalHero() {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap", 
        minHeight: "90vh",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100vh", 
          flex: "1 1 50%", 
        }}
      >
        <div
          style={{
            position: "absolute",
            inset: 0,
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
          }}
        >
          <img
            src={Car1}
            alt="Mercedes AMG GT"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%", 
          padding: "2rem",
          flex: "1 1 50%", 
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ maxWidth: "40rem", margin: "0 auto" }}>
          <div
            style={{
              display: "inline-block",
              backgroundColor: "#e0f2ff",
              padding: "0.5rem 1rem",
              borderRadius: "9999px",
              marginBottom: "1.5rem",
            }}
          >
            <span
              style={{
                fontSize: "0.875rem",
                color: "#0077cc",
                fontWeight: "500",
              }}
            >
              WHY CHOOSE US
            </span>
          </div>

          <h1
            style={{
              fontSize: "clamp(1.5rem, 2vw, 2.25rem)", 
              fontWeight: "bold",
              marginBottom: "3rem",
              textAlign: "center", 
            }}
          >
            We offer the best experience with our rental deals
          </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <Feature
              icon={BadgeCheck}
              title="Best price guaranteed"
              description="Find a lower price? We'll refund you 100% of the difference."
            />
            <Feature
              icon={Car}
              title="Experience driver"
              description="Don't have your own driver? We have many experienced drivers."
            />
            <Feature
              icon={Clock}
              title="24-hour car delivery"
              description="Book your car anytime and we will deliver it directly to you."
            />
            <Feature
              icon={Headphones}
              title="24/7 technical support"
              description="Have a question? Our support team is here to help you 24/7."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Feature({ icon: Icon, title, description }) {
  return (
    <div style={{ display: "flex", alignItems: "start", gap: "1rem" }}>
      <div
        style={{
          backgroundColor: "#e0f2ff",
          padding: "0.5rem",
          borderRadius: "0.5rem",
        }}
      >
        <Icon style={{ width: "2.25rem", height: "2.25rem", color: "#0077cc" }} />
      </div>
      <div>
        <h3 style={{ fontWeight: "600", marginBottom: "0.25rem" }}>{title}</h3>
        <p style={{ fontSize: "0.875rem", color: "#4b5563" }}>{description}</p>
      </div>
    </div>
  );
}
