import React from 'react'; 
import ComingSoon from '../components/comingsoon';
// import { useNavigate } from 'react-router-dom'; 
// import './Login.css'; 
// import img1 from '../assets/a2.png';
// import google from '../assets/google.svg';
// import facebook from '../assets/facebook.svg';


function Signup() {
    // const navigate = useNavigate(); 

  return (
    // <div className="login-container">
    //   <div className="login-content">
    //     <img src={img1} alt="Car" className="login-image" />
        
    //     <div className="login-card">
    //       <h2>Signup</h2>
          
    //       <form>
    //         <div className="form-group">
    //           <label htmlFor="name">Username</label>
    //           <input type="text" id="name" name="name" required />
    //         </div>
    //         <div className="form-group">
    //           <label htmlFor="email">Email or Phone</label>
    //           <input type="email" id="email" name="email" required />
    //         </div>
            
    //         <div className="form-group">
    //           <div className="password-label">
    //             <label htmlFor="password">Password</label>
                
    //           </div>
    //           <input type="password" id="password" name="password" required />
    //         </div>
            
    //         <button type="submit" className="login-btn">Sign Up</button>
    //       </form>
          
    //       <div className="login-links">
    //         <div className="line-or">
    //           <hr className="line" />
    //           <span className="dash">Or Sign Up with</span>
    //           <hr className="line" />
    //         </div>
            
    //         <div className="login-icons">
    //           <button type="button">
    //             <img src={google} alt="Google" />
    //           </button>
    //           <button type="button">
    //           <img src={facebook} alt="Facebook" />
    //           </button>
    //         </div>
    //       </div>

    //       <div className="signup-link">
    //         <p>Not a member?</p>
    //         <button onClick={() => navigate('/Signup')} type="button" className="signup">Sign Up</button>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <ComingSoon />
  );
}

export default Signup;
