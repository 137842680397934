import React from "react";
import { useNavigate } from "react-router-dom";
import location from "../assets/location.png";
import sms from "../assets/sms.png";
import call from "../assets/call.png";
import { Facebook, Instagram, Youtube } from "lucide-react";

function Footer() {
  const navigate = useNavigate();

  const footerData = [
    {
      title: "CatoDrive",
      items: [
        { icon: location, text: "Lorem ipsum cycgic uycfsicsbocnsdiudng" },
        { icon: call, text: "XXXX-XXXX-XX" },
        { icon: sms, text: "catodrive@gmail.com" },
      ],
      isContact: true,
    },
    {
      title: "Our Product",
      items: ["Career", "Car", "Packages", "Features", "Priceline"],
      links: ["/career", "/car", "/packages", "/features", "/priceline"],
    },
    {
      title: "Resources",
      items: ["Download", "Help Centre", "Guides", "Partner Network", "Cruises", "Developer"],
      links: ["/download", "/help", "/guides", "/partners", "/cruises", "/developer"],
    },
    {
      title: "About Rentcars",
      items: ["Why choose us", "Our Story", "Investor Relations", "Press Center", "Advertise"],
      links: ["/whyus", "/story", "/investors", "/press", "/advertise"],
    },
  ];

  const socialIcons = [
    { icon: <Facebook size={28} />, link: "https://facebook.com" },
    { icon: <Instagram size={28} />, link: "https://instagram.com" },
    { icon: <Youtube size={28} />, link: "https://youtube.com" },
  ];

  return (
    <div className="footer">
      <div className="footer-container">
        {/* CatoDrive Section */}
        <div className="footer-catodrive">
          <h4 className="catodrive-title">{footerData[0].title}</h4>
          <ul>
            {footerData[0].items.map((item, i) => (
              <li key={i} className="contact-item">
                <img src={item.icon} alt="icon" className="contact-icon" />
                <span >{item.text}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* All Other Sections */}
        <div className="footer-main-sections">
          {footerData.slice(1).map((section, index) => (
            <div key={index} className="footer-section">
              <h4>{section.title}</h4>
              <ul>
                {section.items.map((item, i) => (
                  <li key={i} onClick={() => navigate(section.links[i])}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {/* Social Media Section */}
          <div className="footer-section social-icons">
            <h4>Follow Us</h4>
            <div className="social-icons-container">
              {socialIcons.map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>Copyright 2024 • CatoDrive, All Rights Reserved</p>
        <div className="footer-links">
          <span onClick={() => navigate("/privacy")}>Privacy & Policy</span>
          <span onClick={() => navigate("/terms")}>Terms & Condition</span>
        </div>
      </div>

      <style jsx>{`
  .footer {
    background-color: #2C3E50;
    color: #abb0b4; /* Main text color */
    padding: 40px 100px;
    margin-top: 100px;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
  }

  /* CatoDrive Section */
  .footer-catodrive {
    flex: 1;
  }

  .catodrive-title,
  .footer-section h4, /* Make all headings white */
  .social-icons h4 {
    color: white; /* White color for all headings */
    font-size: 24px;
    margin-bottom: 20px;
  }

  .footer-catodrive ul {
    list-style: none;
    padding: 0;
  }

  .footer-catodrive ul li {
    font-size: 18px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }

  .contact-item {
    display: flex;
    align-items: center;
  }
  .contact-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  /* Main Sections */
  .footer-main-sections {
    display: flex;
    flex: 2;
    justify-content: space-between;
    gap: 30px;
  }

  .footer-section {
    flex: 1;
    min-width: 150px;
  }

  .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .footer-section ul li {
    font-size: 18px;
    margin-bottom: 12px;
    cursor: pointer;
    color: #abb0b4; /* Keep the list items in #abb0b4 */
  }

  .footer-section ul li:hover {
    color: #D35400;
    text-decoration: underline;
  }

  /* Social Media Section */
  .social-icons-container {
    display: flex;
    gap: 15px;
  }

  .social-icon {
    color: #abb0b4;
    cursor: pointer;
  }

  .social-icon:hover {
    color: #D35400;
  }

  /* Footer Bottom Section */
  .footer-bottom {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-bottom p {
    font-size: 16px;
        color: white;
  }

  .footer-links span {
    margin-left: 20px;
    font-size: 16px;
    cursor: pointer;
    color: white;
  }

  .footer-links span:hover {
    text-decoration: underline;
    color: #D35400;
  }

  @media screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }

    .footer-main-sections {
      flex-direction: column;
      align-items: center;
    }

    .footer {
      padding: 20px;
    }
  }
`}</style>

    </div>
  );
}

export default Footer;
