import React from "react";
import hero from "../assets/hero.png";
import "../pages/Home.css";

const ComingSoon = () => {
    return (
        <>
            <div className="hero-section">
                <div className="hero-container">
                    <div className="hero-text a">
                        <h1>Coming Soon</h1>
                        <div className="hero-cta-div a">
                            <button className="cta-btn"></button>
                            <button className="cta-btn1"></button>
                        </div>
                    </div>
                    <div className="hero-image">
                        <img src={hero} alt="Hero" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ComingSoon;