import React from "react";
import facebook from "../assets/facebook.png";
import insta from "../assets/insta.png";
import twitter from "../assets/x.png";
import whatsapp from "../assets/whats.png";
import telegram from "../assets/tele.png";
import hero from "../assets/hero.png";
import himage1 from "../assets/himage1.png";
import himage2 from "../assets/himage2.png";
import himage3 from "../assets/himage3.svg";
import car1 from "../assets/car1.png"
import car2 from "../assets/car2.png"
import car3 from "../assets/car3.png"
import car4 from "../assets/car4.png"
import Frame from "../assets/Frame.png"
import Frame24 from "../assets/Frame 24.png"
import Frame25 from "../assets/Frame 25.png"
import Frame26 from "../assets/Frame 26.png"
import Frame27 from "../assets/Frame 27.png"
import Frame28 from "../assets/Frame 28.png"

import "./Home.css";
import Testimonials from "../components/Testimonials";
import Joinus from "../components/Joinus";
import About from "../components/About";
import WhyUs from "../components/whyus";


const Home = () => {
  return (
    <>
    <div className="hero-section">
      <div className="hero-container">
        
        <div className="social-media">
          <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
            <img src={insta} alt="Instagram" />
          </a>
          <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href="https://www.whatsapp.com/" target="_blank" rel="noopener noreferrer">
            <img src={whatsapp} alt="WhatsApp" />
          </a>
          <a href="https://www.telegram.org/" target="_blank" rel="noopener noreferrer">
            <img src={telegram} alt="Telegram" />
          </a>
        </div>

        <div className="hero-text">
          <h1>
            Drive Your Dreams  <br />with Our
            Reliable <span className="highlight-text">Rentals</span>
          </h1>
          <p>
          Explore our wide range of rental cars at unbeatable prices. Whether it's a road trip, business travel, or everyday commute, we've got the perfect ride for you
          </p>
          <button className="hero-btn">Get Started</button>
          <div className="hero-cta-div">
          <button className="cta-btn"></button>
          <button className="cta-btn1"></button>
          </div>

        </div>

        <div className="hero-image">
          <img src={hero} alt="Hero" className="img-fluid" />
        </div>
      </div>

      
    </div>

    <div className="search-section">

    <div class="search-bar">
    <div class="input-group">
      <label for="location">
        <i class="icon-location"></i> Location
      </label>
      <input type="text" id="location" placeholder="Search your location"/>
    </div>
    <div class="input-group">
      <label for="pickup-date">
        <i class="icon-calendar"></i> Pickup date
      </label>
      <input type="datetime-local" id="pickup-date"/>
    </div>
    <div class="input-group">
      <label for="return-date">
        <i class="icon-calendar"></i> Return date
      </label>
      <input type="datetime-local" id="return-date"/>
    </div>
    <button class="search-button">Search</button>
  </div>
    </div>

    <div className="How-section">
      <div className="how-div">
        <p className="blue-box">How It Works</p>
        <h2 className="h2">
           Rent with following 3 working steps
        </h2>

        <div className="how-cards">
          <div className="card-box">
            <img src={himage1} alt="Hero" className="img-fluid" />
            <h3>Choose location</h3>
            <p>
            Choose your and find
            your best car
            </p>
          </div>

          <div className="card-box">
            <img src={himage2} alt="Hero" className="img-fluid" />
            <h3>Pick-up date</h3>
            <p>
            Select your pick up date and
time to book your car
            </p>
          </div>

          <div className="card-box">
            <img src={himage3} alt="Hero" className="img-fluid" />
            <h3>Book your car</h3>
            <p>
            Book your car and we will deliver
it directly to you
            </p>
          </div>
        </div>

      </div>

      
    </div>


    <div className="brand-section" style={{ overflow: 'hidden', width: '100%', position: 'relative', margin: '0 auto' }}>
    <div style={{ textAlign: 'center', marginBottom: '40px' }}>
  <h2 style={{ fontSize: '32px', fontWeight: 'bold', color: '#333' }}>Our Car Brands</h2>
</div>

  <div style={{
    display: 'flex',
    animation: 'scroll 15s linear infinite'
  }}>
<img src={Frame24} alt="Jaguar" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame25} alt="Nissan" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame26} alt="Volvo" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame27} alt="Audi" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame28} alt="Acura" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame24} alt="Jaguar" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame25} alt="Nissan" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame26} alt="Volvo" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame27} alt="Audi" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />
<img src={Frame28} alt="Acura" style={{ width: '150px', margin: '0 40px', objectFit: 'contain' }} />

  </div>
  <style>
    {`
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }
    `}
  </style>
</div>



   <div className="popular-section">
      <div className="popular-div">
        <p className="blue-box">POPULAR RENTAL DEALS</p>
        <h2 className="h2">
          Most popular cars rental deals
        </h2>

        <div class="car-cards">
  <div class="card">
    <img src={car1} alt="Jaguar XE L P250" />
    <h3>Jaguar XE L P250</h3>
    <div class="rating">
      <span>⭐ 4.8</span>
      <p>(2,436 reviews)</p>
    </div>
    <ul class="features">
      <li>4 Passengers</li>
      <li>Auto</li>
      <li>Air Conditioning</li>
      <li>4 Doors</li>
    </ul>
    <div class="price">
      <p><strong>$1,800</strong>/day</p>
    </div>
    <button className="r">Rent Now →</button>
  </div>

  <div class="card">
    <img src={car2} alt="Audi R8" />
    <h3>Audi R8</h3>
    <div class="rating">
      <span>⭐ 4.6</span>
      <p>(1,936 reviews)</p>
    </div>
    <ul class="features">
      <li>2 Passengers</li>
      <li>Auto</li>
      <li>Air Conditioning</li>
      <li>2 Doors</li>
    </ul>
    <div class="price">
      <p><strong>$2,100</strong>/day</p>
    </div>
    <button className="r">Rent Now →</button>
  </div>

  <div class="card">
    <img src={car3} alt="Audi R8" />
    <h3>Audi R8</h3>
    <div class="rating">
      <span>⭐ 4.5</span>
      <p>(2,036 reviews)</p>
    </div>
    <ul class="features">
      <li>2 Passengers</li>
      <li>Auto</li>
      <li>Air Conditioning</li>
      <li>2 Doors</li>
    </ul>
    <div class="price">
      <p><strong>$2,100</strong>/day</p>
    </div>
    <button className="r">Rent Now →</button>
  </div>

  <div class="card">
    <img src={car4} alt="Audi R8" />
    <h3>Audi R8</h3>
    <div class="rating">
      <span>⭐ 4.3</span>
      <p>(2,236 reviews)</p>
    </div>
    <ul class="features">
      <li>2 Passengers</li>
      <li>Auto</li>
      <li>Air Conditioning</li>
      <li>2 Doors</li>
    </ul>
    <div class="price">
      <p><strong>$2,100</strong>/day</p>
    </div>
    <button className="r">Rent Now →</button>
  </div>

  
</div>
<button
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    padding: "0.5rem 1rem",
    border: "1px solid #ccc",
    borderRadius: "0.5rem",
    backgroundColor: "white",
    color: "#555",
    fontSize: "0.875rem",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.3s ease",
    marginTop:'50px'
  }}
>
  Show all vehicles
  <span style={{ fontSize: "1.25rem" }}>→</span>
</button>
      </div>
    </div>
    <WhyUs/> 
    <div className="testimo-section">
      <div className="testimo-div">
      <p className="blue-box">TESTIMONIALS</p>
        <h2 className="h2">
          What peole say about us?
        </h2>

       
      </div>
      <Testimonials/>
       </div>
     <Joinus/>
      <About/>
    </>

    
  );
};

export default Home;
