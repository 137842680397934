import React from 'react';
import ComingSoon from '../components/comingsoon';

function Customer() {
  return (
    <ComingSoon />
  )
}

export default Customer;
