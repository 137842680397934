import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; 
import "swiper/css";
import "swiper/css/navigation";
import image1 from "../assets/Rectangle 8.png";

const testimonials = [
  {
    id: 1,
    name: "Sarah Wilson",
    location: "New York, US",
    rating: 5.0,
    feedback: "I’ve been using your services for years. Your service is great, I will continue to use your service.",
    image: image1,
  },
  {
    id: 2,
    name: "Charlie Johnson",
    location: "From New York, US",
    rating: 5.0,
    feedback: "I feel very secure when using caretell's services. Your customer care team is very enthusiastic and the driver is always on time.",
    image: image1,
  },
  {
    id: 3,
    name: "Emily Brown",
    location: "California, US",
    rating: 5.0,
    feedback: "The service was exceptional! Highly recommend your company to anyone looking for professional car rentals.",
    image: image1,
  },
  {
    id: 4,
    name: "Charlie Johnson",
    location: "From New York, US",
    rating: 5.0,
    feedback: "I feel very secure when using caretell's services. Your customer care team is very enthusiastic and the driver is always on time.",
    image: image1,
  },
];

const Testimonials = () => {
  return (
    <>
      <section className="testimonials">
        <Swiper
          modules={[Navigation, Autoplay]}
          navigation
          autoplay={{ delay: 3000 }}
          loop
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="testimonial-card">
                <img src={testimonial.image} alt={testimonial.name} className="testimonial-img" />
                <div className="testimonial-content">
                  <h3>{testimonial.rating} stars</h3>
                  <p className="testimonial-feedback">"{testimonial.feedback}"</p>
                  <p className="testimonial-name">
                    <strong>{testimonial.name}</strong>
                  </p>
                  <p className="testimonial-location">{testimonial.location}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <style jsx>{`
        .testimonials {
          text-align: center;
          width: 100%;
        }

        .testimonial-cards {
          display: flex;
          justify-content: center;
          gap: 20px;
        }

        .testimonial-card {
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          text-align: left;
          overflow: hidden;
          display: flex;
          align-items: center;
        }

        .testimonial-img {
          width: 100%;
          object-fit: cover;
        }

        .testimonial-content {
          padding: 20px;
          width: 397px;
        }

        .testimonial-content h3 {
          color: #ffcc00;
          font-size: 20px;
          margin-bottom: 10px;
        }

        .testimonial-feedback {
          font-size: 14px;
          font-style: italic;
          color: #555;
          margin: 10px 0;
        }

        .testimonial-name {
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }

        .testimonial-location {
          font-size: 12px;
          color: #777;
        }
      `}</style>
    </>
  );
};

export default Testimonials;
