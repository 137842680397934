import React from 'react';
import iphone from '../assets/iPhone 14 Pro Space Black Mockup.png';
import vector from '../assets/Vector.png';
import image2 from '../assets/image 2.png';
import image3 from '../assets/image 3.png';

const About = () => {
  return (
    <div
      style={{
        display:'flex',
        height: '75vh',
        width: '100%',
        marginBottom: '-100px',
        position: 'relative',
        backgroundColor: 'white',
      }}
    > <div style={{width:'50%' , display:'flex' , justifyContent:'center' ,  alignItems:'center' }} > <img
    src={vector}
    alt="Vector"
    style={{
      position: 'absolute',
      height: '580px',
      width: '854px',
    }}
  />
  <div
      style={{
        width: '700px',
        height: '400px',
        marginLeft: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '20px',
        borderRadius: '10px',
        color: 'white',
      }}
    >  <button style={{color:'#1572d3' , height:'45px' , width:'150px' , border:'1px', borderRadius:'5px' , backgroundColor:'#dae4f0'}}>Download</button>
      <h1 style={{ fontSize: '45px', marginBottom: '10px', color: 'black' }}>
        Download CatoDrive <br/> App  for{' '}
        <span style={{ color: 'orange' }}>FREE</span>
      </h1>
      <p style={{ fontSize: '18px', marginBottom: '20px', color: 'gray' }}>
        For faster, easier booking and exclusive deals.
      </p>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start',
        }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
          alt="Google Play"
          style={{ width: '120px', height: 'auto', cursor: 'pointer' }}
        />
        <img
          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
          alt="App Store"
          style={{ width: '120px', height: 'auto', cursor: 'pointer' }}
        />
      </div>
    </div>  </div>
     <div> <img
        src={iphone}
        alt="iPhone"
        style={{
          position: 'absolute',
          height: '460px', 
          right: '100px',
          bottom: '0',
        }}
      /></div>
     
    </div>
  );
};

export default About;
