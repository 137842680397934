import React from 'react';
import ComingSoon from '../components/comingsoon';

function Renter() {
    return (
        <ComingSoon />
);
};


export default Renter;
