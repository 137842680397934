import React from "react";
import ComingSoon from "../components/comingsoon";

const Vehicles = () => {
    

    return (
        <ComingSoon />
    );
};

export default Vehicles
