import React, { useState } from 'react';
import { Play, Check, ChevronDown } from 'lucide-react';

export default function WhyChooseUs() {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  return (
    <section className="py-16 px-4 md:px-6 lg:px-8 max-w-7xl mx-auto">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Why Choose Us</h2>
      
      {/* Features Section */}
      <div className="grid gap-8 lg:grid-cols-2 mb-16">
        <div className="flex items-center">
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold leading-tight">
            Where every drive feels extraordinary
          </h3>
        </div>

        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <Feature 
            title="Variety Brands"
            description="Access our diverse fleet that suits every taste and need. From luxury brands to family-friendly vehicles."
          />
          <Feature 
            title="Maximum Freedom"
            description="Experience premium vehicles with easy extension options. No hidden charges for extended bookings."
          />
          <Feature 
            title="Flexibility On The Go"
            description="Make pickup easy and quick in our location. We ensure your experience is smooth and hassle-free."
          />
        </div>
      </div>

      {/* Statistics Section */}
      <div className="space-y-12 mb-16">
        <div className="relative aspect-video w-full max-w-2xl mx-auto rounded-lg overflow-hidden bg-gray-100">
          <div className="absolute inset-0 flex items-center justify-center">
            <button className="h-16 w-16 flex items-center justify-center rounded-full bg-white/90 shadow-lg hover:bg-white transition-colors">
              <Play className="w-6 h-6 text-blue-600" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-8 max-w-3xl mx-auto text-center">
          <Statistic number="20k+" label="Happy customers" />
          <Statistic number="540+" label="Expert of cars" />
          <Statistic number="25+" label="Years of experience" />
        </div>
      </div>

      {/* Memories Section */}
      <div className="grid gap-8 lg:grid-cols-2 items-center mb-16">
        <div className="space-y-8">
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold leading-tight">
            Unlock unforgettable memories on the road
          </h3>
          <div className="grid gap-4">
            <MemoryItem text="Browse and book our luxury cars. We'll have it ready perfect waiting at your preferred location." />
            <MemoryItem text="Enjoy your premium vehicle with our top-notch service and flexible booking options." />
            <MemoryItem text="Return your car at any of our convenient locations across the city." />
            <MemoryItem text="Share your experience and join our community of satisfied customers." />
          </div>
        </div>

        <div className="relative aspect-[4/3] w-full">
          <div className="absolute inset-0 bg-blue-100 rounded-lg overflow-hidden">
            <img
              src="/placeholder.svg?height=400&width=600"
              alt="Luxury car on the road"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>

      {/* Download App Section */}
      <div className="relative bg-blue-600 rounded-xl p-8 md:p-12 overflow-hidden mb-16">
        <div className="grid gap-8 lg:grid-cols-2 items-center relative z-10">
          <div className="flex justify-center lg:justify-end">
            <div className="w-48 h-96 border-8 border-white rounded-[3rem] bg-white relative">
              <div className="absolute top-0 left-0 right-0 h-7 bg-white rounded-t-[2rem] flex justify-center">
                <div className="w-20 h-4 bg-black rounded-b-xl"></div>
              </div>
            </div>
          </div>

          <div className="text-white lg:pl-8">
            <h3 className="text-2xl md:text-3xl font-bold mb-4">Download our app</h3>
            <p className="text-white/80 mb-8 max-w-md">
              Getting started has never been easier. Download our app now and experience the convenience of booking your dream car with just a few taps.
            </p>
            <div className="flex flex-wrap gap-4">
              <AppStoreButton store="App Store" />
              <AppStoreButton store="Play Store" />
            </div>
          </div>
        </div>
      </div>



      {/* Reviews Section */}
      <div className="py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Reviews from our customers</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <Review 
            quote="Et aliquet nunc et system perfectionist nulla tare dignissim neque. Nulla quis sagittis neque in dapibus Nulla quis tellus sit."
            author="Emmanuel Doyle"
          />
          <Review 
            quote="Porta consectetur tellus duis urna placerat purus nulla. Nam tincidunt nunc id dapibus. Lorem ipsum dolor sit amet adipiscing."
            author="Rose Greene"
          />
          <Review 
            quote="Quam neque ultricra autpretium felis. Sed egestas magna in dapibus Nulla quis tellus sit amet consectetur adipiscing."
            author="Ryker Nelson"
          />
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Top Asked Questions</h2>
        <div className="max-w-3xl mx-auto">
          <Accordion
            items={[
              {
                question: "How does it work?",
                answer: "Imperdiet et tristique euismod nunc. Ultricies arcu vel accumsan cursus turpis ultricies neque. Pernullamper urna ut ac in. Proin nunc nunc mattis interdum et. Sed nunc id dapibus Nulla quis tellus sit amet consectetur adipiscing elit. Sed egestas magna in dapibus Nulla quis tellus sit."
              },
              {
                question: "Can I rent a car without a credit card?",
                answer: "Yes, you can rent a car without a credit card. We accept various payment methods including debit cards and digital payments. However, additional documentation may be required."
              },
              {
                question: "What are the requirements for renting a car?",
                answer: "To rent a car, you must be at least 21 years old, have a valid driver's license, provide proof of insurance, and meet our credit requirements. Additional requirements may apply for luxury vehicles."
              },
              {
                question: "Does Car+ drive allow me to tow with or attach a hitch to the rental vehicle?",
                answer: "Towing capabilities vary by vehicle. Please contact our customer service team to discuss specific towing requirements and available vehicles that meet your needs."
              },
              {
                question: "Does Car+ drive offer coverage products for purchase with my rental?",
                answer: "Yes, we offer various coverage options including collision damage waiver, personal accident insurance, and supplemental liability protection. Our team can help you choose the right coverage for your needs."
              }
            ]}
            openAccordion={openAccordion}
            toggleAccordion={toggleAccordion}
          />
        </div>
      </div>

       {/* Car Banner */}
       <CarBanner />
    </section>
  );
}

function Feature({ title, description }) {
  return (
    <div className="space-y-3">
      <h4 className="text-lg font-semibold">{title}</h4>
      <p className="text-sm text-gray-600 leading-relaxed">{description}</p>
    </div>
  );
}

function Statistic({ number, label }) {
  return (
    <div className="space-y-2">
      <div className="text-3xl md:text-4xl font-bold text-blue-600">{number}</div>
      <div className="text-sm text-gray-600">{label}</div>
    </div>
  );
}

function MemoryItem({ text }) {
  return (
    <div className="flex items-start gap-2">
      <div className="mt-1">
        <Check className="h-5 w-5 text-blue-600" />
      </div>
      <p className="text-sm text-gray-600">{text}</p>
    </div>
  );
}

function AppStoreButton({ store }) {
  return (
    <a href="#" className="bg-black hover:bg-gray-900 transition-colors rounded-lg px-6 py-3 flex items-center gap-2">
      <img
        src="/placeholder.svg?height=24&width=24"
        alt={store}
        className="w-6 h-6"
      />
      <span className="text-white">{store}</span>
    </a>
  );
}

function CarBanner() {
  return (
    <div className="bg-blue-600 rounded-xl overflow-hidden relative mb-16">
      <div className="px-8 py-6 md:py-8 relative z-10">
        <div className="max-w-xl">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-2">
            Looking for a car?
          </h2>
          <p className="text-white/90 text-lg mb-4">
            +XXX-XXX-XXXX
          </p>
          <button className="bg-white text-blue-600 px-6 py-2 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
            Book now
          </button>
        </div>
      </div>
      <div className="absolute right-0 top-1/2 -translate-y-1/2 w-1/2 h-full hidden md:block">
        <img
          src="/Frame%20130.png"
          alt="BMW 2-Series Coupe"
          className="object-contain w-full h-full"
        />
      </div>
    </div>
  );
}

function Review({ quote, author }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="space-y-4">
        <blockquote className="text-lg italic">"{quote}"</blockquote>
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
          <div>
            <div className="font-semibold">{author}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Accordion({ items, openAccordion, toggleAccordion }) {
  return (
    <div className="space-y-4">
      {items.map((item, index) => (
        <div key={index} className="border rounded-lg overflow-hidden">
          <button
            className="flex justify-between items-center w-full p-4 text-left font-semibold"
            onClick={() => toggleAccordion(index)}
          >
            {item.question}
            <ChevronDown className={`w-5 h-5 transition-transform ${openAccordion === index ? 'transform rotate-180' : ''}`} />
          </button>
          {openAccordion === index && (
            <div className="p-4 bg-gray-50">
              <p>{item.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

