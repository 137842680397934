import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Select from './pages/select';
import VehicleOwner from './pages/vehicleowner';
import Customer from './pages/Customer';
import Header from './components/Header';
import Login from './pages/login';
import Signup from './pages/signup';
import Whyus from './pages/WhyUs';
import Contact from './pages/contact';
import Renter from './pages/renter';
import Vehicles from './pages/vehicles';
import Footer from './components/footer';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Select" element={<Select />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/vehicle-owner" element={<VehicleOwner />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/whyus" element={<Whyus />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/renter" element={<Renter />} />
          <Route path="/vehicles" element={<Vehicles />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
